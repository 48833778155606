/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.4.2
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2022 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/stores/store.js";
import LightBootstrap from './light-bootstrap-main';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Plugins
import App from './App.vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import AudioRecorder from 'vue-audio-recorder';

// router setup
import routes from './routes/routes';
// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
Vue.use(pinia);
Vue.use(store);
Vue.use(BootstrapVue);
Vue.use(AudioRecorder);

// configure router
import { router } from './routes/routes';

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  pinia,
  store,
  globalOptions: {
    timeout: 100000, // 100 seconds
  }
});
